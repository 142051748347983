<template>
	<div class="bindLogis">
		<div class="Title" style="padding: 15px 0 15px 15px;">
			<el-button type="primary" size="small" @click="getDeliveryName">获取物流公司</el-button>
		</div>
		<div class="table-container" style="padding: 20px;">
			<el-table :data="tableData" v-loading="loading" border style="width: 100%">
				<el-table-column prop="DeliveryName" label="物流公司">
				</el-table-column>
				<el-table-column prop="BizId" label="客户编号"></el-table-column>
				<el-table-column prop="BindStateDisplay" label="绑定状态"></el-table-column>
			</el-table>
			<div style="text-align: right;">
				<el-pagination v-if="total" style="margin-top:20px;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import config from '@/config/index'
	import {bindAccountlist,RefreshExpressinfo}  from '@/api/TurnTomySelf'
	export default {
		data(){
			return{
				loading:false,
				tableData: [],
				currentPage: 1,
				pageSize: 20,
				total: 0,
			}
		},
		created() {
			this.getList()
		},
		methods:{
			async getDeliveryName(){
				try{
					this.loading = true
					let result = await RefreshExpressinfo({})
					if(result.IsSuccess){
						this.getList()
						console.log(result,'接口数据')
					}
					
				}catch(err){
					
				}finally{
					this.loading = false
				}
				
			},
			async getList(){
				try{
					this.loading = true
					let data = {
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await bindAccountlist(data)
					this.total = result.Result.Total;
					this.tableData = result.Result.Results || [];
				}catch(err){
					
				}finally{
					this.loading = false
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less">
	.bindLogis {
		background: #fff;
		font-size: 14px;
	}
</style>
